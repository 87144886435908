@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-display: swap;
    font-style: normal;
    font-family: "Montserrat";
    src: url("../public/font/Montserrat-VariableFont_wght.ttf");
}

@layer base {

    p {
        @apply mb-6;
    }

    h4 {
        @apply text-accent;
        @apply text-3xl;
        @apply lg:text-4xl;
        @apply my-3.5;
        @apply font-bold;
    }

    h1 {
        @apply uppercase;
        @apply text-4xl;
        @apply lg:text-5xl;
        @apply xl:text-6xl;
        @apply 2xl:text-8xl;
        @apply text-accent;
        @apply font-black;
        @apply mb-6;
        @apply lg:mb-12;
        @apply w-full;

    }

    .welcome h1 {
        @apply lg:px-0;
    }

    h2 {
        @apply uppercase;
        @apply text-3xl;
        @apply lg:text-7xl;
        @apply text-accent;
        @apply font-black;
        @apply mb-12;
        @apply lg:mb-24;
        @apply px-4;
    }

    .logo h1 {
        @apply text-lg;
        @apply lg:text-4xl;
        @apply mb-0;
    }


    .heading-effect-a {
        position: relative;
        overflow: visible;
    }

    .heading-effect-a::before {
        content: attr(data-text);
        position: absolute;
        top: -30px;
        display: none;
    }

    footer h2 {
        @apply text-white;
        @apply font-bold;
        @apply text-2xl;
        @apply lg:text-3xl;
        @apply xl:text-4xl;
        @apply xl:text-5xl;
        @apply py-4;
        @apply px-0;
        @apply mt-6;
        @apply xl:mt-0;
        @apply xl:py-24;
        @apply mb-0;
    }

    .news-list h2 {
        @apply uppercase;
        @apply text-7xl;
        @apply text-accent;
        @apply font-black;
        @apply mb-24;
    }


    .main-content h3 {
        @apply font-bold;
        @apply text-2xl;
        @apply mb-8;
    }

    .main-content h4 {
        @apply font-bold;
        @apply text-xl;
        @apply mb-4;
    }

    .main-content h5 {
        @apply font-bold;
        @apply text-lg;
        @apply mb-3.5;
    }

    .main-content p {
        @apply mb-0;
        @apply leading-relaxed
    }

    .main-content p:empty {
        @apply mb-6;
    }


    address p {
        @apply mb-0;
        @apply not-italic;
    }

    .main-content u {
        @apply no-underline;
        @apply font-medium;
    }

    .main-content a {
        @apply underline;
        @apply transition-all;
        @apply duration-300;
    }

    .main-content a:hover,
    main .mail-and-phone a:hover {
        @apply text-accent;
    }

    .contact-teaser .mail-and-phone a:hover {
        @apply !underline;
        @apply !text-white;
    }


    @media only screen and(max-width: 767px) {
        .rotating-rings {
            height: 250px;
        }
    }
    @media only screen and(min-width: 768px) {
        .rotating-rings {
            height: calc(1.2 * 100vh);
        }
    }

    .curtain {
        background: transparent;
        background: linear-gradient(160deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 20%)
        /*background: linear-gradient(164deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%); */
    }


    .menu-icon {
        @apply cursor-pointer;
        @apply p-5;
        @apply fixed;
        @apply right-2;
        @apply top-7;
        @apply lg:hidden
    }


    .menu-line {
        @apply bg-accent;
        @apply block;
        @apply h-[2px];
        @apply relative;
        @apply w-[24px];
        @apply z-10;
        @apply lg:hidden;

    }



    .menu-line::before,
    .menu-line::after {
        @apply bg-accent;
        @apply block;
        @apply h-full;
        @apply absolute;
        @apply transition-all;
        @apply duration-300;
        @apply ease-out;
        @apply w-full;
        content: '';
        @apply lg:hidden;
    }

    .main-nav.front .menu-line::before,
    .main-nav.front .menu-line::after,
    .main-nav.front .menu-line {
        @apply bg-white;
    }

    .main-nav.front.scrolled .menu-line::before,
    .main-nav.front.scrolled .menu-line::after,
    .main-nav.front.scrolled .menu-line {
        @apply bg-accent
    }

    .menu-line::before {
        @apply lg:hidden;
        @apply top-2.5;
        @apply z-10;
    }

    .menu-line::after {
        @apply lg:hidden;
        @apply -top-2.5;
        @apply z-10;
    }

    .toggle-menu {
        @apply hidden;
    }

    .toggle-menu:checked ~ nav {
        @apply max-h-full;
        @apply h-full;

    }

    .toggle-menu:checked ~ .menu-icon .menu-line {
        @apply bg-transparent;
    }

    .toggle-menu:checked ~ .menu-icon .menu-line::before {
        transform: rotate(-45deg);
        @apply top-0;
    }

    .toggle-menu:checked ~ .menu-icon .menu-line::after {
        transform: rotate(45deg);
        @apply top-0;
    }



}
